import React from "react"
import {Link} from "gatsby"

const Menu = () => {
    return (
        <ul style={{
            listStyleType: 'none'
        }}>
            <li style={{display: 'inline', marginRight: '10px'}}><Link to='/'>Home</Link></li>
            <li style={{display: 'inline', marginRight: '10px'}}><Link to='/pocket'>Pocket</Link></li>
        </ul>
    )
}

export default Menu