/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          fullname
          pgp {
            key
          }
          social {
            twitter
            linkedin
          }
        }
      }
    }
  `)

  const { author, fullname, social, pgp } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Written by <strong>{author}</strong> ({fullname}). <br />
        I'm DevOps Swiss Army knife, from Dijon (France). <br />
        {` `}
        <a href={`https://twitter.com/${social.twitter}`} target='_blank' rel='noopener noreferrer'>
          Twitter
        </a>
        {` `}
        <a href={`https://www.linkedin.com/in/${social.linkedin}`} target='_blank' rel='noopener noreferrer'>
          LinkedIn
        </a>
        {` `}
        <a href={`https://pgp.key-server.io/search/${pgp.key}`} target='_blank' rel='noopener noreferrer'>
          PGP Key
        </a>
        {` `}
        <a href={`https://github.com/TheGuit`} target='_blank' rel='noopener noreferrer'>
          Github
        </a>
      </p>
    </div>
  )
}

export default Bio
